import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandLess, HelpOutline } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExcavatorModal, TruckModal } from '~/components/EquipmentStatusModals';
import PF2MEquipmentPool from '~/components/PF2MEquipmentPool';
import PF2MTooltip from '~/components/PF2MTooltip';
import {
  TruckAllocationModal,
  TruckAllocationModeModal,
  LoadAllocationModal,
} from '../../LoadSchedules/AllocationsTab/AllocationModal';
import OriginRow from './OriginRow';
import {
  getDispatchOriginsTrucks,
  getDispatchOriginsExcavators,
  updateTruckAllocations,
  setPendingTruckAllocationUpdate,
  setSelectedTruckAllocation,
  setSelectedLoadAllocation,
  getSyncStatus,
  getEquipmentStatuses,
} from '~/store/dispatch/actions';
import DispatchLabels from '../DispatchLabels';
import TruckOperators from '../TruckOperatorStatus';
import PF2MEquipmentScheduleModal from '~/components/PF2MEquipmentScheduleModal';
import PF2MFullScreenButton from '~/components/PF2MFullScreenButton';
import ContextMenu, { PF2MMenuItem, useContextMenu } from '~/components/PF2MMenuContext';
import { withModal } from '~/components/PF2MModal';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MWarnFeatureFlag from '~/PF2MWarnFeatureFlag';
import usePlatformConfiguration from '~/services/platformConfiguration';
import CollapseEquipmentList from '../CollapseEquipmentList';
import styles from '../styles';
import { DispatchDragNDropTypes, DispatchStatus } from '~/utils';
import useTabletConfiguration from '~/services/tabletConfiguration';
import { newMomentDate } from '~/utils/moment';
import { getCodes, getOperators } from '~/store/manager/actions';

// const TRUCK = 1;
const LOAD = 2;

// No meaning to have validation for one line temporary toggle feature
// eslint-disable-next-line react/destructuring-assignment, react/prop-types
const OffComp = props => <div>{props.children}</div>;

const GENERIC_LOAD_EQUIP = {
  id: null,
  equip_type_id: 1,
  equip_group_id: 0,
  equip_id: 0,
  equip_name: '',
  priority: null,
  origin_element_id: null,
  origin_element_point_id: null,
  status: 'OP',
};

const EquipmentScheduleModal = withModal(PF2MEquipmentScheduleModal);

const OriginsTab = ({ classes }) => {
  const rootRef = React.useRef(0);

  const dispatch = useDispatch();
  const [currentTruckModal, setCurrentTruckModal] = useState(null);
  const [currentExcavatorModal, setCurrentExcavatorModal] = useState(null);
  const { t: translate } = useTranslation();
  const [truckContextMenuData, openTruckContextMenu, closeTruckContextMenu] = useContextMenu();
  const [loadContextMenuData, openLoadContextMenu, closeLoadContextMenu] = useContextMenu();
  const [truckAllocationModalData, setTruckAllocationModalData] = useState();
  const [loadAllocationModalData, setLoadAllocationModalData] = useState();
  const equipmentsSchedules = useSelector(state => state.dispatch.equipmentsSchedules);
  const [equipmentScheduleModal, setEquipmentScheduleModal] = useState(false);
  const processDisptachIndicatorsTime = (
    usePlatformConfiguration('process_dispatch_indicators_time')?.value || '0'
  );
  const [expanded, setExpanded] = useState(true);
  const handleFS = useFullScreenHandle();

  const openTruckModal = (data) => {
    dispatch(getSyncStatus({ group_id: data.equip_group_id },
      res => setCurrentTruckModal({
        ...data,
        ...res.find(item => item.equip_id === data.equip_id),
      })));
  };

  const openExcavatorModal = (data) => {
    dispatch(getSyncStatus({ group_id: data.equip_group_id },
      res => setCurrentExcavatorModal({
        ...data,
        ...res.find(item => item.equip_id === data.equip_id),
      })));
  };

  const pendingTruckAllocationUpdate = useSelector(
    state => state.dispatch.pendingTruckAllocationUpdate,
  );
  const loadAllocations = useSelector(state => state.dispatch.loadAllocations);
  const excavatorRows = useSelector(
    state => [
      ...state.dispatch.excavatorOrigins
        .filter(r => r.priority !== null),
      ...state.dispatch.excavatorOrigins
        .filter(r => r.priority === null && r.status === DispatchStatus.OP),
    ],
  );
  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses);
  const codes = useSelector(state => state.manager.codes);
  const operators = useSelector(state => state.manager.operators);

  const durationsByExcavatorRows = excavatorRows.map((excavator) => {
    const matchingStatuses = equipmentStatuses.filter(
      status => status.id_equip === excavator.equip_id,
    );

    const durations = matchingStatuses.flatMap(status => status.duration);

    const mappedStatuses = matchingStatuses.map((status) => {
      const foundCode = codes.find(c => c.id === status.id_code);
      const foundOperator = operators.find(op => op.id_operator === status.id_operator);

      return {
        ...status,
        codeName: foundCode ? foundCode.name : translate('common:CodeNotFound'),
        operatorName: foundOperator ? foundOperator.name : translate('common:OperatorNotFound'),
      };
    });

    const codesNames = mappedStatuses.map(status => status.codeName);
    const operatorsNames = mappedStatuses.map(status => status.operatorName);

    return {
      ...excavator,
      durations,
      codes_name: codesNames,
      operators_name: operatorsNames,
    };
  });

  const availablePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckOrigins
        .map(TruckOperators)
        .filter(st => st.isHealthy && !st.isOnCicle && !st.isOnQuickPause)
        .map(({ to }) => to),
      ...state.dispatch.excavatorOrigins
        .filter(r => !r.priority
          && (r.status === DispatchStatus.PO || r.status === DispatchStatus.IN)),
    ],
  );
  const maintenancePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckOrigins
        .map(TruckOperators)
        .filter(st => !st.isHealthy)
        .map(({ to }) => to),
      ...state.dispatch.excavatorOrigins
        .filter(r => !r.priority && (r.status === DispatchStatus.MA)),
    ],
  );
  const rightLaneTrucks = useSelector(state => state.dispatch.truckOrigins
    .map(TruckOperators)
    .filter(st => st.isHealthy)
    .filter(st => st.isOnCicle || st.isOnQuickPause)
    .filter(st => st.isLoading)
    .map(({ to }) => to));

  const leftLaneTrucks = useSelector(state => state.dispatch.truckOrigins
    .map(TruckOperators)
    .filter(st => st.isHealthy)
    .filter(st => st.isOnCicle || st.isOnQuickPause)
    .filter(st => !st.isLoading)
    .map(({ to }) => to));

  const { value: dispatchUpdateTime } = useTabletConfiguration('dispatch_update_time');

  useEffect(() => {
    function fetchData() {
      dispatch(getDispatchOriginsTrucks());
      dispatch(getDispatchOriginsExcavators());
      dispatch(getEquipmentStatuses());
      dispatch(getCodes());
      dispatch(getOperators());
    }
    if (dispatchUpdateTime !== undefined) {
      fetchData();
      const interval = setInterval(fetchData, dispatchUpdateTime * 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, dispatchUpdateTime]);

  const equipScheduleModalData = useMemo(() => {
    if (!equipmentsSchedules || !equipmentScheduleModal) {
      return {};
    }
    const currentEquip = equipmentsSchedules
      .find(e => (e.group_equipament_links_id === equipmentScheduleModal.equip_id));
    return currentEquip || {
      ...equipmentScheduleModal,
      equipaments_id: equipmentScheduleModal.equip_type_id,
      equipament_groups_id: equipmentScheduleModal.equip_group_id,
      group_equipament_links_id: equipmentScheduleModal.equip_id,
    };
  }, [equipmentScheduleModal, equipmentsSchedules]);

  const allocateTruck = (_e, item) => {
    dispatch(setSelectedTruckAllocation(item));
    setTruckAllocationModalData(true);
  };

  const editTruckSchedule = (_e, item) => {
    setEquipmentScheduleModal(item);
  };

  const allocateLoader = (_e, item) => {
    dispatch(setSelectedLoadAllocation(item));
    setLoadAllocationModalData(LOAD);
  };

  const editLoadSchedule = (_e, item) => {
    setEquipmentScheduleModal(item);
  };

  const renderTableHeader = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 50,
      }}
    >
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:Element')}</span>
      </div>
      <div style={{
        width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      />
      <div style={{
        marginLeft: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <PF2MTooltip
          title={(<DispatchLabels />)}
        >
          <HelpOutline fontSize="small" style={{ marginRight: '5px' }} />
        </PF2MTooltip>
        <span className={classes.labelTypeSelector}>{translate('common:LoadEquipment')}</span>
      </div>
    </div>
  );

  const renderGenericRow = () => {
    const workingExcavators = durationsByExcavatorRows.map(r => r.equip_id);
    const leftLane = leftLaneTrucks
      .filter(r => !workingExcavators.includes(r.current_excavator_id));
    const rightLane = rightLaneTrucks
      .filter(r => !workingExcavators.includes(r.current_excavator_id));

    if (!leftLane.length && !rightLane.length) return null;
    return (
      <OriginRow
        origin={GENERIC_LOAD_EQUIP}
        leftLane={leftLane}
        rightLane={rightLane}
        isGenericRow
        loadAllocations={loadAllocations}
        openTruckModal={openTruckModal}
        openExcavatorModal={openExcavatorModal}
        onTruckContextMenu={openTruckContextMenu}
        onLoadContextMenu={openLoadContextMenu}
      />
    );
  };

  const renderRows = () => (
    <div style={{
      width: '100%',
      flex: 1,
      overflow: 'hidden',
    }}
    >
      {renderTableHeader()}
      <PF2MScrollbar style={{
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      }}
      >
        <>
          {durationsByExcavatorRows
            .map(r => (
              <OriginRow
                key={r.equip_id}
                origin={r}
                leftLane={leftLaneTrucks.filter(x => x.current_excavator_id === r.equip_id)}
                rightLane={rightLaneTrucks.filter(x => x.current_excavator_id === r.equip_id)}
                loadAllocations={loadAllocations}
                onTruckDrop={(item, allocationId) => {
                  if (!item.load_allocation_id) {
                    dispatch(updateTruckAllocations(item, 'load_allocation_id', allocationId, true));
                    return;
                  }

                  const newTruckAllocation = {
                    ...item,
                    load_allocation_id: allocationId,
                  };
                  dispatch(setPendingTruckAllocationUpdate(newTruckAllocation));
                }}
                openTruckModal={openTruckModal}
                openExcavatorModal={openExcavatorModal}
                onTruckContextMenu={openTruckContextMenu}
                onLoadContextMenu={openLoadContextMenu}
              />
            ))}
          {renderGenericRow()}
        </>
      </PF2MScrollbar>
    </div>
  );

  const renderPools = () => (
    <Accordion
      defaultExpanded
      onChange={() => setExpanded(!expanded)}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandLess />}
        id="1"
      >
        <Typography>{translate('common:AvailableEquipments')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{
            display: 'flex',
            marginTop: 10,
            width: '100%',
          }}
          >
            <PF2MEquipmentPool
              equips={availablePoolEquips}
              loadAllocations={loadAllocations}
              onEquipClick={() => null}
              onItemDropped={() => null}
              headerText={translate('common:AvailableEquipments').toUpperCase()}
              truckItemType={DispatchDragNDropTypes.POOL_TRUCK}
              loadItemType={DispatchDragNDropTypes.POOL_LOAD}
              acceptDrop={false}
              onContextMenu={{
                truck: openTruckContextMenu,
                loader: openLoadContextMenu,
              }}
            />
            <PF2MEquipmentPool
              equips={maintenancePoolEquips}
              loadAllocations={loadAllocations}
              onEquipClick={() => null}
              onItemDropped={() => null}
              headerText={translate('common:MaintenanceEquipments').toUpperCase()}
              customStyles={{ marginLeft: 10 }}
              truckItemType={DispatchDragNDropTypes.POOL_TRUCK}
              loadItemType={DispatchDragNDropTypes.POOL_LOAD}
              acceptDrop={false}
              onContextMenu={{
                truck: openTruckContextMenu,
                loader: openLoadContextMenu,
              }}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );

  const children = (
    <div className={classes.tabContainer}>
      <FullScreen handle={handleFS}>
        <PF2MFullScreenButton
          isFullScreenActive={handleFS.active}
          open={handleFS.enter}
          close={handleFS.exit}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: handleFS.active ? '95%' : '82vh',
          }}
        >
          {renderRows()}
          {renderPools()}
        </div>

        <div ref={rootRef}>
          <TruckModal
            modalData={currentTruckModal}
            closeModal={() => setCurrentTruckModal(null)}
            classes={classes}
            container={() => rootRef.current}
          />

          <ExcavatorModal
            modalData={currentExcavatorModal}
            closeModal={() => setCurrentExcavatorModal(null)}
            classes={classes}
            container={() => rootRef.current}
          />

          <TruckAllocationModeModal
            isOpen={!!pendingTruckAllocationUpdate}
            refreshOrigins
            close={() => dispatch(setPendingTruckAllocationUpdate(null))}
            container={() => rootRef.current}
          />

          <ContextMenu
            {...truckContextMenuData}
            close={closeTruckContextMenu}
            container={rootRef.current}
          >
            <PF2MMenuItem onClick={allocateTruck}>
              {translate('common:AddEditAllocation')}
            </PF2MMenuItem>
            <PF2MMenuItem onClick={editTruckSchedule}>
              {translate('common:EditEquipmentScheduling')}
            </PF2MMenuItem>
          </ContextMenu>

          <ContextMenu
            {...loadContextMenuData}
            close={closeLoadContextMenu}
            container={rootRef.current}
          >
            <PF2MMenuItem onClick={allocateLoader}>
              {translate('common:AddEditAllocation')}
            </PF2MMenuItem>
            <PF2MMenuItem onClick={editLoadSchedule}>
              {translate('common:EditEquipmentScheduling')}
            </PF2MMenuItem>
          </ContextMenu>

          <TruckAllocationModal
            modalData={truckAllocationModalData}
            closeModal={() => setTruckAllocationModalData(null)}
            container={() => rootRef.current}
          />

          <LoadAllocationModal
            isOpen={loadAllocationModalData}
            close={() => setLoadAllocationModalData(null)}
            container={() => rootRef.current}
          />

          {equipScheduleModalData && (
            <EquipmentScheduleModal
              container={() => rootRef.current}
              modalData={equipmentScheduleModal}
              closeModal={() => setEquipmentScheduleModal(false)}
              settings={{
                skipBulkUpdate: true,
              }}
              fixed={{
                equipmentType: equipScheduleModalData.equipaments_id || 0,
                equipmentGroup: equipScheduleModalData.equipament_groups_id || 0,
                equipment: equipScheduleModalData.group_equipament_links_id || 0,
                startTime: newMomentDate(),
                duration: '00:30:00',
                element: equipScheduleModalData.flowchart_element_id || 0,
                subElement: equipScheduleModalData.flowchart_element_point_id || 0,
              }}
            />
          )}
        </div>
      </FullScreen>
    </div>
  );
  return (
    <PF2MWarnFeatureFlag
      flag={processDisptachIndicatorsTime >= 1}
      featureName="DispatchIndicators (process_dispatch_indicators_time >= 1)"
      OffComponent={OffComp}
      OnComponent={CollapseEquipmentList}
      props={{
        classes: { container: classes.tabContainer },
        children,
      }}
    />
  );
};

OriginsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

OriginsTab.defaultProps = {};

export default withStyles(styles)(OriginsTab);
